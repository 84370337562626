import { BaseRepository } from 'modules/core/services/base-repository';

export class ExpertisesInExecutionRepository extends BaseRepository {

    baseUrl = 'expertises/in-execution';

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

}
