import { FilesRepository } from 'modules/administration/files/services/repository';
import { GlobalConfig }    from 'resources/configs/global-config';

export class ExpertisesAwaitingExecutionFilesRepository extends FilesRepository {

    baseUri = 'expertises/awaiting-execution/';

    /**
     * Returns the report upload URI
     *
     * @returns {*}
     */
    uploadReportUri() {
        return GlobalConfig.generateApiUrl(`${this.baseUri}files/upload-report`);
    }

    /**
     * Returns the inquiry upload URI
     *
     * @returns {*}
     */
    uploadInquiryUri() {
        return GlobalConfig.generateApiUrl(`${this.baseUri}files/upload-inquiry`);
    }

}
