import { bindable, customElement, inject } from 'aurelia-framework';
import { FormSchema }                      from 'modules/expertises/details/form-schema';

@inject(FormSchema)
@customElement('expertise-details')
export class ExpertiseDetails {

    formId = 'expertises.details-form';

    @bindable model;

    /**
     * Constructor
     *
     * @param formSchema
     */
    constructor(formSchema) {
        this.formSchema = formSchema;
    }

    /**
     * Handles activate event
     */
    bind() {
        this.schema = this.formSchema.schema(this);
    }

}
