import { inject }               from 'aurelia-framework';
import { InspectorsRepository } from 'modules/entities/inspectors/services/repository';
import { ProcedureScheduling }  from 'modules/procedures/models/procedure-scheduling';

@inject(InspectorsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param inspectorsRepository
     */
    constructor(inspectorsRepository) {
        this.inspectorsRepository = inspectorsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ProcedureScheduling}
     */
    model() {
        let model = new ProcedureScheduling();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.inspector_id = {
            type:         'select2',
            key:          'inspector_id',
            label:        'form.field.inspector',
            size:         4,
            options:      [],
            remoteSource: this.inspectorsRepository.activeByEntity.bind(this.inspectorsRepository, viewModel.model.inspecting_entity_id),
        };

        this.scheduled_date = {
            type:            'text',
            key:             'scheduled_date',
            label:           'form.field.scheduled-date',
            size:            4,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateTimeFormat' },
            ],
        };

        this.maintenance_technician = {
            type:       'text',
            key:        'maintenance_technician',
            label:      'form.field.maintenance-technician',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('expertises.awaiting-execution.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                right:      true,
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.inspector_id, this.scheduled_date, this.maintenance_technician],
            [this.buttons],
        ];
    }

}
